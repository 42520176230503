$topPercentage: 70%;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  overflow: hidden;
}

.seed-input {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  z-index: 9;
  pointer-events: none;
  visibility: hidden;

  &::focus {
    outline: none;
  }

  &.active {
    visibility: visible;
    pointer-events: all;
  }
}

.loading {
  height: 100vh;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ccc;
  z-index: 2;
  color: #333;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.mobile-notice {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #fff;
  background: #333333;
  font-family: "Nunito", sans-serif;
  text-align: center;
}

.debug {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 10;
  font-size: 3rem;
  color: pink;
}

.video {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  overflow: hidden;

  &.video-top {
    width: $topPercentage;
    z-index: 1;
    left: (100% - $topPercentage) / 2;
  }

  &.video-bottom {
    overflow: hidden;
    left: -9999px;
    right: -9999px;
    margin: 0 auto;
    min-width: 100%;
    min-height: calc(100vh + 1px);
  }

  &.next {
    z-index: -1;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
